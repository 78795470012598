import React from 'react'

/**
* Diese Klasse enthält alle Teilnehmer der Dart-Weltmeisterschaft 2023 und die dazugehörigen Informationen.
* Die Teilnehmer werden mit einer ID in einem Array gespeichert.
* Der Name und die dazugehöprigen Informationen können über die entsprechenden Methoden erfragt werden.
*/

const participants = 
[

//Top 32 der PDC Order Of Merit
{ "id": 1, "name": "Luke Humphries", "nationality": "ENG", "information": "The 29-year-old goes into his eighth World Championships as the world number one and defending champion. This year he won the World Matchplay, World Cup of Darts & Players Championship Finals. Can he defend his title in London?" },
{ "id": 2, "name": "Michael Smith", "nationality": "ENG", "information": "The 2023 world champions are playing their 14th World Cup in a row this year. Together with Luke Humphries, he won the 2024 World Cup of Darts. " },
{ "id": 3, "name": "Michael van Gerwen", "nationality": "NED", "information": "The youngest world champion in PDC history is taking part in his 18th World Championship at the age of 35. MVG has won three World Championship titles in his career. This year he has made it to two Major Finals." },
{ "id": 4, "name": "Luke Littler", "nationality": "ENG", "information": "The 17-year-old Englishman will be taking part in the World Championships for the second time this year, having reached the final on his debut. This year he won the Premier League and the Grand Slam. How far will he make it in his second World Championship?" },
{ "id": 5, "name": "Rob Cross", "nationality": "ENG", "information": "The Englishman won the 2018 World Championship on his debut. He has been there every year since. This year, he won a title on the European Tour and a title at the World Series. Where is he heading after last year's semi-final?" },
{ "id": 6, "name": "Dave Chisnall", "nationality": "ENG", "information": "The 44-year-old Englishman won two Players Championship tournaments and two European Tour tournaments in 2024. This year, he will be taking part in his 14th World Championship. His best result was a semi-final in 2021." },
{ "id": 7, "name": "Jonny Clayton", "nationality": "WAL", "information": "The ferret is playing its ninth World Championship in a row this year. At the World Grand Prix, he played his way through to the quarter-finals. How far will he make it at this World Championship?" },
{ "id": 8, "name": "Stephen Bunting", "nationality": "ENG", "information": "The 2014 BDO World Champion is taking part in his 11th PDC World Championship this year. At the beginning of the year, the Bullet won the Masters. Where will it take him at the end of the year?" },
{ "id": 9, "name": "Damon Heta", "nationality": "AUS", "information": "The Australian is taking part in his sixth World Championships this year. His best result was a place in the last 16 last year. He made it through to the semi-finals at the UK Open. How far will he go at the World Championships?" },
{ "id": 10, "name": "Gerwyn Price", "nationality": "WAL", "information": "The 39-year-old Welshman will be taking part in the World Championships for the eleventh time in a row. He won the title in 2021. How far can the Iceman go after a weak year?" },
{ "id": 11, "name": "Dimitri Van den Bergh", "nationality": "BEL", "information": "This year's UK Open Champion and two-time Junior World Champion will be taking part in his tenth World Championship this year. The Belgian's best result to date was a semi-final in 2023, so how far will he make it this time?" },
{ "id": 12, "name": "Nathan Aspinall", "nationality": "ENG", "information": "Last year, the Englishman won the World Matchplay, and in 2019 and 2020 he reached the semi-finals of the World Championship, in which he will take part for the seventh time this year. Will he manage such a run again this year?" },
{ "id": 13, "name": "Danny Noppert", "nationality": "NED", "information": "Noppie has played in six World Championships, so he will be travelling to the Ally Pally for the seventh time. The Dutchman reached the semi-finals of one major this year. Will he manage a similar run at the World Championships?" },
{ "id": 14, "name": "Gary Anderson", "nationality": "SCO", "information": "The Flying Scotsman has twice won the PDC World Championship, in which he will take part for the 16th time this year. Most recently he reached the semi-finals of the Grand Slam of Darts, where he played a very good tournament. Can he build on that?" },
{ "id": 15, "name": "Chris Dobey", "nationality": "ENG", "information": "In the last two years, the 2023 Masters winner reached the quarter-finals of the World Championship. He will be competing at the World Championships for the ninth time. Will he achieve a better result this year?" },
{ "id": 16, "name": "James Wade", "nationality": "ENG", "information": "The Machine is taking part in the PDC World Championship for the 21st time. He has reached the semi-finals four times, most recently in 2022. He reached the semi-finals at this year's World Matchplay, but where will he go at the World Championship?" },
{ "id": 17, "name": "Peter Wright", "nationality": "SCO", "information": "The Scot has already won the World Championships twice, and this year he will be taking part for the 16th time. In 2024, he won the European Tour event in Hildesheim. Where will he finish at the World Championship this year?" },
{ "id": 18, "name": "Josh Rock", "nationality": "NIR", "information": "The young Northern Irishman will be taking part in the World Championships for the third time. His best result was the round of 16 on his debut. Will he achieve a better result at this World Championship?" },
{ "id": 19, "name": "Ross Smith", "nationality": "ENG", "information": "The 35-year-old will be taking part in the World Championships for the eighth time. The Englishman's best result came in the last three years, when he reached the third round each time." },
{ "id": 20, "name": "Ryan Searle", "nationality": "ENG", "information": "Heavy metal will be taking part in the World Cup for the seventh time. He has reached the round of 16 three times, which is his best result to date. Will he make it through this year?" },
{ "id": 21, "name": "Andrew Gilding", "nationality": "ENG", "information": "Last year's UK Open champion will be taking part in the World Championships for the sixth time this year. So far, he has always been eliminated in the second round at the latest. Will the Englishman make it further this year?" },
{ "id": 22, "name": "Martin Schindler", "nationality": "GER", "information": "The German number 1 will be seeded for his sixth World Championships this year. He has won two European Tour tournaments this year and thus his first titles. How far will he make it at the World Championships?" },
{ "id": 23, "name": "Joe Cullen", "nationality": "ENG", "information": "The 2022 Masters winner will be taking part in the World Championships for the 15th time this year. He has made it to the round of 16 three times, which is his best result to date." },
{ "id": 24, "name": "Mike De Decker", "nationality": "BEL", "information": "The Belgian qualified for the World Grand Prix this year and was able to win it very surprisingly. The 28-year-old has taken part in the World Championships four times so far, so this year will be his fifth time at the start." },
{ "id": 25, "name": "Dirk van Duijvenbode", "nationality": "NED", "information": "After a less-than-stellar year, the Titan still manages to qualify for the World championship. He will be making his sixth appearance, with his best result being a quarter-final in 2021." },
{ "id": 26, "name": "Daryl Gurney", "nationality": "NIR", "information": "After a good year, Superchin qualifies for his 12th World Championship in total. The former World Grand Prix Champion has so far only made it as far as the quarter-finals." },
{ "id": 27, "name": "Gabriel Clemens", "nationality": "GER", "information": "The German Giant has his work cut out for him in his seventh appearance at the World Championships. Two years ago he reached the semi-finals and this year he has to defend his place. Will the German succeed?" },
{ "id": 28, "name": "Gian van Veen", "nationality": "NED", "information": "Last year, the Dutchman reached the final of the PDC Junior World Championship. This year he won two titles on the Development Tour and managed to qualify for his second World Championship."},
{ "id": 29, "name": "Ritchie Edhouse", "nationality": "ENG", "information": "More than surprisingly, the Englishman won the European Championship this year and with it his first Major title in the PDC. He will be competing in the World Championship for the fifth time this year. Let's see how far his journey takes him." },
{ "id": 30, "name": "Brendan Dolan", "nationality": "NIR", "information": "The Northern Irishman will be competing for the 17th time this year. He made history back then with his 9-darter in the double-in-double-out mode. Can he make history again at this World Championship?" },
{ "id": 31, "name": "Krzysztof Ratajski", "nationality": "POL", "information": "The 2017 BDO World Masters Champion has now held his tour card for six years. This year, the Polish Eagle will take part in his eighth World Championship, with his best result so far being a quarter-final." },
{ "id": 32, "name": "Raymond van Barneveld", "nationality": "NED", "information": "The 2007 World Champion won the Pro Tour this year and is now taking part in the World Championships at Alexandra Palace for the 18th time. Will he make it to the title again?" },


//Top 32 der PDC Pro Tour Order Of Merit
{ "id": 33, "name": "Cameron Menzies", "nationality": "ENG", "information": "This will be Cammy's third time at the Ally Pally. He made it to the second round in each of his previous appearances. How far will he go this time?" },
{ "id": 34, "name": "Wessel Nijman", "nationality": "NED", "information": "The 24-year-old Dutchman has won seven Development Tour tournaments and one Pro Tour tournament this year. Last year, he failed to reach the first round at the World Championships, but this year he is trying again." },
{ "id": 35, "name": "Jermaine Wattimena", "nationality": "NED", "information": "The European Darts Championship finalist qualifies for the World Championship for the 11th time. He has already made it through to the third round twice, which is his best result to date." },
{ "id": 36, "name": "Ricardo Pietreczko", "nationality": "GER", "information": "After making his debut at last year's World Championships, the 30-year-old managed to qualify for several major tournaments this year. Including the World Championships again. Will he make it further than the third round like last year?" },
{ "id": 37, "name": "Luke Woodhouse", "nationality": "ENG", "information": "In 2024, the Englishman reached the semi-finals of a major for the first time. After a strong year, he qualifies for the World championship for the sixth time." },
{ "id": 38, "name": "Ryan Joyce", "nationality": "ENG", "information": "The Englishman qualified for several major tournaments this year, including his debut at the World Matchplay. He will be making his seventh appearance at the Ally Pally this year." },
{ "id": 39, "name": "Niels Zonneveld", "nationality": "NED", "information": "In the Czech Republic, the Dutchman reached the semi-finals of a European Tour tournament. He will be playing the World Championship for the fourth time, where his best result was the second round last year." },
{ "id": 40, "name": "Alan Soutar", "nationality": "SCO", "information": "The Scot has now held his tour card since 2021. With a title on the Pro Tour, among other things, the 46-year-old can now qualify for his third World Championship. So far, he has reached the round of 16 twice." },
{ "id": 41, "name": "Madars Razma", "nationality": "LAT", "information": "The Latvian has been playing in the PDC since 2017 and hasn't missed a World Championship since 2020. So this year will be his 6th appearance. Last year he made it to the third round, which was also his best placing." },
{ "id": 42, "name": "Callan Rydz", "nationality": "ENG", "information": "While he holds the tour card, he has yet to miss a World Championship. The Riot will be competing in the PDC World Championship for the sixth time this year. In 2022, he even reached the quarter-finals. Will he manage that again?" },
{ "id": 43, "name": "Wesley Plaisier", "nationality": "NED", "information": "As number two on the Challenge Tour, the Dutchman will not have a tour card until next year and will play actively on the PDC. He will also be making his debut this year, which he achieved with a win on the Pro Tour. Where is the 34-year-old heading?" },
{ "id": 44, "name": "Martin Lukeman", "nationality": "ENG", "information": "The surprise finalist of the Grand Slam of Darts has qualified for the World Championship for the third time this year. In previous years, he made it through to the second round." },
{ "id": 45, "name": "Kevin Doets", "nationality": "NED", "information": "Last year, the 26-year-old lost to defending champion Michael Smith on the very first evening. This year he made it to the World championships again. Will it go better this time?" },
{ "id": 46, "name": "Richard Veenstra", "nationality": "NED", "information": "The Dutchman only got his tour card last year and has been playing with the pros ever since. This is the second time he has managed to qualify for the World Championship. He made it through to round three on his debut." },
{ "id": 47, "name": "Scott Williams", "nationality": "ENG", "information": "Last year, the Englishman surprisingly reached the semi-finals. This year will be his third time on the biggest stage in darts. Will he manage a similar run to last year?" },
{ "id": 48, "name": "Kim Huybrechts", "nationality": "BEL", "information": "The Belgian has been a permanent fixture in the PDC since 2012. Since then he has not missed a World Championship, which means that this year he will be competing for the 14th time in the World Championship." },
{ "id": 49, "name": "William O'Connor", "nationality": "IRL", "information": "The Irishman has not missed a World Championship at the PDC since 2018. He will be taking part in this event for the eighth time, with the third round his best result to date." },
{ "id": 50, "name": "Karel Sedlacek", "nationality": "CZE", "information": "The Czech has now taken part in the PDC World Championship three times. His best result was a second round two years ago. Will he make it further this year?" },
{ "id": 51, "name": "Stephen Burton", "nationality": "ENG", "information": "The Englishman has been playing in the PDC since 2014 and has had a tour card since 2023. He is back at the World Championship after six years, which is only the second World Championship of his career." },
{ "id": 52, "name": "Thibault Tricole", "nationality": "FRA", "information": "He played himself into the final of the WDF World Championship 2022 and then became the first Frenchman ever to play in the PDC World Championship, in which he will take part for the second time this year." },
{ "id": 53, "name": "Connor Scutt", "nationality": "ENG", "information": "As number one on the Challenge Tour, the 28-year-old will have a tour card again from January and play on the ProTour again. He has won a total of two tournaments and qualified for his second World Championship this year." },
{ "id": 54, "name": "Mensur Suljovic", "nationality": "AUT", "information": "The Gentle has had a tour card since 2011 and has only missed two World Championships (2013&2024) since 2008. So this year he celebrates his comeback on the biggest stage in darts." },
{ "id": 55, "name": "Ian White", "nationality": "ENG", "information": "Although his last title win was 4 years ago, the Diamond should never be underestimated. He will be on stage at the Ally Pally for the 13th time. How far will it go for him?" },
{ "id": 56, "name": "Jeffrey de Graaf", "nationality": "SWE", "information": "The Swede had already qualified for the Ally Pally earlier in the year by winning the PDC Nordic & Baltic Tour. Now, however, he has also qualified for the Pro Tour. Will the lottery pot change be an advantage for him and will he be able to repeat last year's third round?" },
{ "id": 57, "name": "Jim Williams", "nationality": "WAL", "information": "In 2020, the Welshman reached the final of the BDO World Championship before switching to the PDC, where he has had a tour card since 2022. His best result at a PDC World Championship so far was a third round. Where will his fourth World Championship take him?" },
{ "id": 58, "name": "Florian Hempel", "nationality": "GER", "information": "The 34-year-old played his way to the Players Championship Finals for the first time this year. He also managed to throw a 9-darter in a qualifier. Once again, he narrowly managed to qualify for the World Championship, which will be his fourth World Championship." },
{ "id": 59, "name": "Ryan Meikle", "nationality": "ENG", "information": "After the Barber had to take a break from the World Championship last year, he will be back at the start this year. After participating in six Junior World Championships, he will be back at the Ally Pally for the fifth time." },
{ "id": 60, "name": "Chris Landman", "nationality": "NED", "information": "The former WDF World Championship finalist qualified for his second appearance at the Ally Pally this year. He had previously reached the second round in 2022. Will he be able to top that result this year?" },
{ "id": 61, "name": "Nick Kenny", "nationality": "WAL", "information": "The young Welshman once reached the second round of the PDC World Championship. This year he is taking part for the third time. Will he make it further this time?" },
{ "id": 62, "name": "James Hurrell", "nationality": "ENG", "information": "The Englishman has only had his tour card since January 2024. With several good results on the Pro Tour, he managed to place so well in the rankings that he will make his debut at the PDC World Championship." },
{ "id": 63, "name": "Mickey Mansell", "nationality": "NIR", "information": "The Northern Irishman made the run of his life this year: a semi-final at the Grand Slam of Darts. Will he be able to transfer this good performance to the World Championship? He has never made it past the second round at the Ally Pally." },
{ "id": 64, "name": "Ricky Evans", "nationality": "ENG", "information": "At the last World Championship, the Rapid reached the third round of the World Championship only to reach the semi-finals of the UK Open a short time later. The Englishman will be taking part in the World Championship for the 10th time." },
 //Die 32 internationalen Qualifikanten
{ "id": 65, "name": "Xiao Chen Zong", "nationality": "CHN", "information": "The 26-year-old qualified for this year's World Championship via the China Premier League. It will be his third appearance at the Ally Pally, although he has always finished in the first round." },
{ "id": 66, "name": "Robert Owen", "nationality": "ENG", "information": "(Attention complicated) Due to the disqualification of Dom Taylor, Robert Owen was able to move up into the field of participants for the World Championship. Although he qualified via the Pro Tour, he is in the draw for the international qualifiers. This is because he takes the place of Jeffrey de Graaf, who had previously qualified via the PDC Nordic & Baltic Tour, but has now qualified via the Pro Tour as well, thus freeing up a place in the pot of international qualifiers." },
{ "id": 67, "name": "Darius Labanauskas", "nationality": "LTU", "information": "The Lithuanian reached a semi-final on the Challenge Tour this year. As runner-up on the Nordic & Baltic Tour, he qualifies for his sixth World Championship in total." },
{ "id": 68, "name": "Tomoya Goto", "nationality": "JPN", "information": "The Japanese player has one tournament win on the Asian Tour and one participation in the World Series of Darts in Bahrain to his name. He makes it to the Ally Pally via the PDJ Qualifier. Will his journey go further than the second round last year?" },
{ "id": 69, "name": "Joe Comito", "nationality": "AUS", "information": "The Australian ultimately emerged as the winner of the DPA Tour on three occasions. Last year, he also managed a 9-darter on this tour. At the age of 43, he will make his World Championship debut this year." },
{ "id": 70, "name": "Leonard Gates", "nationality": "USA", "information": "Last year's World Seniors Masters and Matchplay Champion won both the CDC Continental Cup and three tournaments on the CDC Tour this year, which is why he won them. He will be competing in the World Championship for the second time." },
{ "id": 71, "name": "Jim Long", "nationality": "CAN", "information": "The Canadian reached the semi-finals of this year's World Seniors Championship. He achieved two wins on the CDC Tour and finished as runner-up, which qualified him for his second World Championship." },
{ "id": 72, "name": "Stowe Buntz", "nationality": "USA", "information": "His best result on the circuit so far was reaching the quarter-finals of the Grand Slam of Darts last year. As third on the CDC Tour (with two tournament wins) he qualified for his second World Championship." },
{ "id": 73, "name": "Cameron Carolissen", "nationality": "RSA", "information": "He played his first and, to date, last World Championship in 2021, but the South African is returning this year. He won two tournaments on the African Continental Tour and secured his comeback by winning the African Qualifier." },
{ "id": 74, "name": "Alexis Toylo", "nationality": "PHI", "information": "This year the Philippine celebrated his World Cup of Darts debut and will make his World Championship debut. He won five tournaments on the PDC Asian Tour and finished the tour in first place." },
{ "id": 75, "name": "Paolo Nebrida", "nationality": "PHI", "information": "With three tournament wins, the 39-year-old was able to play himself into second place on the PDC Asian Tour, qualifying for his third World Championship." },
{ "id": 76, "name": "Ryusei Azemoto", "nationality": "JPN", "information": "To reach third place on the Asian Tour, the Japanese player had to reach 4 tournaments on the tour. These performances brought him to his World Championship debut this year." },
{ "id": 77, "name": "Lourence Ilagan", "nationality": "PHI", "information": "The Philippine also qualifies for the World Championships as fourth-placed in the Asian Tour. This will be the ninth time he has competed in the event. His best World Championship was two years ago, when he made it through to the second round." },
{ "id": 78, "name": "Ben Robb", "nationality": "NZL", "information": "The New Zealander scored two victories on the DPNZ Tour. He also won the DPNZ Qualifier, qualifying for his fifth World Championship." },
{ "id": 79, "name": "Rashad Sweeting", "nationality": "BAH", "information": "Very little is known about the man from the Bahamas. The 34-year-old won the CLDC Tour and can therefore celebrate his World Championship debut this year." },
{ "id": 80, "name": "Niko Springer", "nationality": "GER", "information": "He is regarded as one of the greatest talents in German darts. He has won three Development Tour and four next gen tournaments. After three PDC Youth World Championships, he will play his World Championship debut at the Ally Pally this year." },
{ "id": 81, "name": "Keane Barry", "nationality": "IRL", "information": "The 22-year-old is regarded as one of the greatest Irish talents in darts. With three titles on the Development Tour, he has climbed to third place in the rankings and qualified for his sixth World Championship." },
{ "id": 82, "name": "Noa-Lynn van Leuven", "nationality": "NED", "information": "The Dutchwoman is the first trans woman to take part in a PDC World Championship. She won four titles on the Women's Series this year and finished the season in second place, which brings her first to the Grand Slam and now to her World Championship debut." },
{ "id": 83, "name": "Fallon Sherrock", "nationality": "ENG", "information": "She made history as the first woman to win a match at the World Championship. Three titles on the Women's Series and the cancellation by Beau Greaves brings the Englishwoman back to Ally Pally." },
{ "id": 84, "name": "Gordon Mathers", "nationality": "AUS", "information": "The Australian won two titles on the DPA Tour this year. Via the DPA Oceanic Masters, he secured a place at the World Championship, which he will be taking part in for the third time this year." },
{ "id": 85, "name": "Sandro Eric Sosing", "nationality": "PHI", "information": "Another Philippine qualifies for the World Championship. He makes it to his second World Championship in a row via the PDC Asian Tour. Will he make it further this year than last year?" },
{ "id": 86, "name": "Lok Yin Lee", "nationality": "HKG", "information": "The semi-final at the PDC Asian Championship and a good ranking on the Asian Tour ensured that the 23-year-old will make his debut at the Ally Pally." },
{ "id": 87, "name": "Nitin Kumar", "nationality": "IND", "information": "After not taking part in the World Championships for the last two years, the Indian will be competing again this year. He was by far the best player at the Indian qualifying tournament." },
{ "id": 88, "name": "Christian Kist", "nationality": "NED", "information": "The Dutchman won three titles and a 9-darter this year on the Challenge Tour, which he finished in third place, bringing him back to the Ally Pally this year." },
{ "id": 89, "name": "Alexander Merkx", "nationality": "NED", "information": "The Dutchman played on the Challenge Tour this year, where he won two titles. These performances will bring him to his World Championship debut this year." },
{ "id": 90, "name": "Kai Gotthardt", "nationality": "GER", "information": "This year's German champion played on the Pro Tour as a late entrant, won one week of the Super Series mode and played in the Super League. He first played his way into the final and won it furiously to make his World Championship debut." },
{ "id": 91, "name": "Stefan Bellmont", "nationality": "CHE", "information": "He will be the first Swiss to take part in a PDC World Championship. He won the Western European Qualifier in November and will therefore make his debut at the Ally Pally this year." },
{ "id": 92, "name": "Romeo Grbavac", "nationality": "CRO", "information": "In 2023, the Croatian made his debut at the World Cup of Darts before competing in three European Tour tournaments this year. By winning the East Europe Qualifiers, he will also make his debut at the Ally Pally this year." },
{ "id": 93, "name": "Rhys Griffin", "nationality": "WAL", "information": "After the young Welshman won his tour card in 2024, he reached the last 16 on the Pro Tour and took part in the World Series of Darts Finals in Amsterdam. His performance in the PDPA Qualifier now sees him make his debut in the AllyPally." },
{ "id": 94, "name": "Jeffrey De Zwaan", "nationality": "NED", "information": "The Black Cobra won one of four places at the Ally Pally in the PDPA Qualifier. He defeated Paul Krohne in the deciding match to secure his place. He has been travelling on the tour for many years. Will he be able to better his best result at the World Championships (Last 16 in 2020) this year?" },
{ "id": 95, "name": "Dylan Slevin", "nationality": "IRL", "information": "The young Irishman made his world championship debut last year, where he was eliminated in the first round. After reaching the semi-finals of the World Youth Championships this year, he is back at the Ally Pally again this year. He defeated Martijn Dragt in the PDPA Qualifier final. Will he make it past the first round this year?" },
{ "id": 96, "name": "Matt Campbell", "nationality": "CAN", "information": "The canoe called Ginja Ninja achieved its best World Championship result last year, finishing third. This year he has qualified for the AllyPally via the PDPA Qualifier and will try to repeat his good result from last year." }

]









/**
 * Diese Methode liefert den Namen des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem der Name erfragt werden soll
 * @returns Name des Spielers als String, zu der dazugehörigen ID - wenn kein Name vorhanden return undefined
 */
export function getParticipantName(id)
{
    if(participants[id-1])
    {
        //Optional: Falls Spieler unter Top 32 wird die Setzlistenposition mit angegeben
        if(id <= 32)
        {
            return participants[id-1].name + " (" + id + ")";
        }

        if(participants[id-1].name == "Mensur Suljovic")
        {
            return "Mensur Suljović";
        }

        return participants[id-1].name;
        
    }
    return undefined;
}

/**
 * Diese Methode liefert die Informationen des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem die Informationen erfragt werden soll
 * @returns Informationen des Spielers als String, zu der dazugehörigen ID - wenn keine Informationen vorhanden return undefined
 */
export function getParticipantInformation(id)
{
    if(participants[id-1])
    {
        return participants[id-1].information;
    }
    return undefined;
}

/**
 * Diese Methode liefert die Nationalität des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem die Nationalität erfragt werden soll
 * @returns Nationalität des Spielers als String, zu der dazugehörigen ID - wenn keine Nationalität vorhanden return undefined
 */
 export function getParticipantNationality(id)
 {
     if(participants[id-1])
     {
         return participants[id-1].nationality;
     }
     return undefined;
 }

 export function getParticipantNameForPDF(id)
{
    if(participants[id-1])
    {
        //Optional: Falls Spieler unter Top 32 wird die Setzlistenposition mit angegeben
        if(id <= 32)
        {
            return participants[id-1].name + " (" + id + ")";
        }

            if(participants[id-1].name == "Boris Krčmar")
            {
                return "Boris Krcmar";
            }
            else if(participants[id-1].name == "José Justicia")
            {
                return "Jose Justicia";
            }

        return participants[id-1].name;
        
    }
    return "";
}