import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCtYmcwm5jESVO7bjzU5t7jMlEFvS6RGK0",
    authDomain: "mydartcoach-database.firebaseapp.com",
    databaseURL: "https://mydartcoach-database.firebaseio.com",
    projectId: "mydartcoach-database",
    storageBucket: "mydartcoach-database.appspot.com",
    messagingSenderId: "347639249121",
    appId: "1:347639249121:android:2ba2c9022d701395075fa0",
};

const Firebase = initializeApp(firebaseConfig);
export const database = getDatabase(Firebase);
export default Firebase;

